.classic-container {
  background-size: cover !important;
}

.selected { 
    animation: float 6s ease-in-out infinite;
  }
  
  
  @keyframes float {
      0% {
          box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
          transform: translatey(0px);
      }
      50% {
          box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
          transform: translatey(-20px);
      }
      100% {
          box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
          transform: translatey(0px);
      }
  }
  
  .special-card.special-card-lock {
    margin-top: 13vh;
  }
  .key-card {
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // background-image: radial-gradient(farthest-corner at 40px 40px, #111 20%, #333 100%, #ddd 100%);
    background-color: transparent !important;

  }
  .special-card {
    border: 0 !important;
    // background: rgba(39, 41, 61, 0.95);
    // border: 0;
    // position: relative;
    // width: 100%;
    // margin-bottom: 30px;
    // box-shadow: 0 1px 20px 0 rgb(0 0 0 / 10%);
  }
  .special-card {
    background-color: transparent !important;
    // display: flex;
    // flex-direction: column;
    // min-width: 420px;
    // word-wrap: break-word;
    /* background-color: #fff; */
    // background-clip: border-box;
    // border: 0.0625rem solid rgba(34,42,66,.05);
    // border-radius: 0.2857rem;
  }.special-card .special-card-header {
    padding: 15px 15px 0;
    border: 0;
    color: hsla(0,0%,100%,.8);
  }.special-card-header:first-child {
    border-radius: 0.2232rem 0.2232rem 0 0;
  }.special-card-lock .special-card-header img {
    width: 120px;
    height: 120px;
    border-radius: 4px;
    margin-top: -60px;
    box-shadow: 0 10px 25px 0 rgb(0 0 0 / 30%);
  }
  
  .special-card-user .special-card-body {
    // min-height: 240px;
  }
  .special-card .special-card-body {
    // padding: 15px;
  }
  .special-card-user .author {
    text-align: center;
    text-transform: none;
    margin-top: 25px;
  }
  // rgba(255,223,88,255)
  .special-card-user .author .block.block-one, .special-card-user .author .block.block-two {
    background: rgba(255,223,88,.6);
    background: linear-gradient(
  90deg,rgba(255,223,88,.6) 0,rgba(255,223,88,0));
  }
  .special-card-user .author .block.block-one {
    transform: rotate(
  150deg);
    margin-top: -90px;
    margin-left: -50px;
  }
  .special-card-user .author .block {
    position: absolute;
    height: 100px;
    width: 250px;
  }
  .special-card-user .author .block.block-one, .special-card-user .author .block.block-two {
    background: rgba(255,223,88,.6);
    background: linear-gradient(
  90deg,rgba(255,223,88,.6) 0,rgba(255,223,88,0));
  }
  .special-card-user .author .block.block-two {
    transform: rotate(
  30deg);
    margin-top: -40px;
    margin-left: -100px;
  }
  .special-card-user .author .block.block-four, .special-card-user .author .block.block-three {
    background: rgba(255,223,88,.6);
    background: linear-gradient(
  90deg,rgba(255,223,88,.6) 0,rgba(255,223,88,0));
    right: -45px;
  }
  .special-card-user .author .block.block-three {
    transform: rotate(
  170deg);
    margin-top: -70px;
  }
  .special-card-user .author .block.block-four, .special-card-user .author .block.block-three {
    background: rgba(255,223,88,.6);
    background: linear-gradient(
  90deg,rgba(255,223,88,.6) 0,rgba(255,223,88,0));
    right: -45px;
  }
  .special-card-user .author .block.block-four {
    transform: rotate(
  150deg);
    margin-top: -25px;
  }
  .special-card-user .avatar {
    width: 256px;
    height: 256px;
    border: 5px solid white !important;
    border-bottom-color: transparent !important;
    background-color: transparent !important;
    position: relative;
  }
  .special-card .avatar {
    width: 256px;
    height: 256px;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  .avatar {
    color: #fff;
    background-color: #adb5bd;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: 50%;
    height: 48px;
    width: 48px;
  }
  
  .avatar-sm {
    width: 86px !important;
    height: 86px  !important;
    border-radius: 10px;
  }
  
  .damage-result {
    animation: pop 0.3s linear 1;
  }
  
  @keyframes pop{
    50%  {transform: scale(1.2);}
  }
  

  
  @media (max-width: 600px) {
    .avatar {
      width: 128px !important;
      height: 128px !important;
    }
    .proverb {
      font-size: 18px !important;
    }
    .inp {
      font-size: 18px;
      padding: 0;
      max-width: 32px;
    }
    .premint-button {
      width: 180px !important;
    }
  }
  

.avatar {
    width: 256px;
    height: 256px;
    border: 5px solid white !important;
    border-bottom-color: transparent !important;
    background-color: transparent !important;
    position: relative;
  }
 .avatar {
    width: 256px;
    height: 256px;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  .avatar {
    color: #fff;
    background-color: #adb5bd;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: 50%;
    height: 48px;
    width: 48px;
  }


.special-card-user {
  overflow: hidden;
}

.special-card h1, .special-card h2, .special-card h3, .special-card h4, .special-card h5, .special-card h6, .special-card p, h1, h2, h3, h4, h5, h6 {
  color: hsla(0,0%,100%,.8);
}
.title {
  font-weight: 600;
}
.h5, h5 {
  line-height: 1.4em;
  margin-bottom: 15px;
}
.h5, h5 {
  font-size: 1.3125rem;
}

.special-card-user .author a+p.description {
  margin-top: -7px;
}
.special-card-description, .special-card .footer .stats, .description, .footer-big p {
  color: #9a9a9a;
  font-weight: 300;
}

.badge-attribute {
  background: #27293d;
  /* background-image: linear-gradient(to bottom left,#f43f5e,#ff4655,#f43f5e); */
  background-size: 210% 210%;
  background-position: 100% 0;
  background-color: #27293d;
  transition: all .15s ease;
  box-shadow: none;
  color: #fff;
  margin-right: 8px;
  border: 1px solid rgba(255,255,255,0.35);
  padding: 8px;
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 500;
}


.mr-auto {
  margin-right: auto;
}


.ml-auto {
  margin-left: auto;
}

.progress-bar{
    background-color: rgb(244,63,94) !important;
}

.fancy-text {
  text-shadow: 0px 15px 5px rgba(0,0,0,0.1),
  10px 20px 5px rgba(0,0,0,0.05),
  -10px 20px 5px rgba(0,0,0,0.05);
}

.double-exp {
  width: 100%;
}

.btn-reward {
  background-color: #fbd85c;
  color: black;
}
