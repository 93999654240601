.wrapper {
  text-align: center;
}

.widget {
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 7px 14px;
  font-weight: 500;
  font-size: 12px;
  background-color: var(--boxBg);
  border-radius: 30px;
  transition: transform 300ms ease-out;

  &:hover,
  &:focus {
    transform: translateY(-2px);

    span {
      opacity: 0;
    }

    .cover {
      opacity: 1;
    }
  }
}

.widgetHidden {
  composes: widget;
  opacity: 0;
}

.icon {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  width: 17px;
  height: 1px;
  margin-right: 8px;

  &.playing {
    height: 32px;
    & > span {
      animation: bounce 2.2s ease infinite alternate;
    }
  }

  & > span {
    width: 3px;
    height: 100%;
    background-color: var(--active-color);
    border-radius: 3px;
    transform-origin: bottom;
    transition: opacity 0.3s ease-out;
    content: '';

    &:nth-of-type(2) {
      animation-delay: -2.2s;
    }

    &:nth-of-type(3) {
      animation-delay: -3.7s;
    }
  }
}


.icon.playing {
  animation: colorChange 20s alternate infinite;
}

@keyframes colorChange {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}


.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

@keyframes bounce {
  10% {
    transform: scaleY(0.3);
  }

  30% {
    transform: scaleY(1);
  }

  60% {
    transform: scaleY(0.5);
  }

  80% {
    transform: scaleY(0.75);
  }

  100% {
    transform: scaleY(0.6);
  }
}