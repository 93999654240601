:root {
  --white: #fff;
  --black: #000;
  --brand: black;
  --active-color: #00aeb0;--tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

* {
  box-sizing: border-box;
}

/* html {
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  background: var(--active-color);
  transition: background 0.4s ease;
} */

.audio-buttons {
  background: none;
  border: none;
  cursor: pointer;
}

input[type="range"] {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #3b7677;
  transition: background 0.2s ease;
  cursor: pointer;
}

.audio-player {
  /* max-width: 350px; */
  /* padding: 12px; */
  /* border-radius: 20px; */
  background-color: #FAFAFA;
  box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(229, 229, 229);
  /* margin: auto; */
  /* color: var(--white); */
}

.artwork {
  /* border-radius: 120px; */
  /* display: block; */
  height: 64px;
  width: 64px;
  /* margin-right: 12px; */
}

.track-info {
  text-align: center;
  z-index: 1;
  position: relative;
  height: 4rem;
}

.title-audio {
  font-weight: 700;
  margin-bottom: 0px;
}

.artist {
  font-weight: 300;
  margin-bottom: 0;
}

.audio-controls {
  display: flex;
  justify-content: space-between;
  width: 75%;
  /* margin: 0 auto 15px; */
}

.audio-controls .prev svg,
.audio-controls .next svg {
  width: 20px;
  height: 20px;
}

.audio-controls .play-button svg,
.audio-controls .pause svg {
  height: 26px;
  width: 26px;
}

.audio-controls path {
  fill: var(--black);
}

.color-backdrop {
  background: linear-gradient(45deg, var(--active-color) 20%, transparent 100%)
    no-repeat;
  border: solid 1px var(--active-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.color-backdrop.playing {
  animation: colorChange 20s alternate infinite;
}

@keyframes colorChange {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

.transform {
  height: 7rem;
  width: 7rem;
  transform: translateY(-42px);
}